import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ITC from "../components/InTheCrates"

const SecondPage = () => (
  <Layout imageVariation="6">
    <SEO title="In The Crates Radio" />
    <ITC />
  </Layout>
)

export default SecondPage
