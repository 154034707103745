import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Motion from "../images/motion.jpg"

const episodes = [
  {
    url: "https://fanlink.to/InTheCratesRadio",
    title: "In The Crates #1",
    cover: "https://i.postimg.cc/jSrHPV76/radio.jpg",
  },
]

const Radio = ({ siteTitle }) => (
  <div className="flex flex-col justify-between mb-8 flex-wrap md:flex-no-wrap m-auto">
    <h3 className="uppercase font-bold text-4xl mb-4"> Episodes</h3>

    {episodes.map((item, key) => (
      <div id="release" className="flex flex-row">
        {/* COVER IMAGE */}
        <a href={item.url} className="w-4/12 mr-4">
          <img src={item.cover} />
        </a>

        {/*  TEXT */}
        <div id="release-details" class="h-full md:w-48 ">
          <p className="font-bold">{item.title}</p>
        </div>
      </div>
    ))}
  </div>
)

export default Radio
